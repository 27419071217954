<template>
  <v-card class="mt-2">
    <v-card-title>Toimeksiannon tiedot</v-card-title>

    <v-container>
      <!-- Assignments in use -->
      <v-row dense class="mb-2">
        <v-col cols="6" md="4" lg="3" xl="2">
          <v-checkbox
            v-model="currentApartment.assignment.inUse"
            label="Toimeksiantosopimus käytössä"
            style="margin-top: 0px"
            hide-details
          >
          </v-checkbox>
        </v-col>
      </v-row>

      <div v-if="currentApartment.assignment.inUse">
        <h4>Voimassaolo</h4>

        <!-- Dates -->
        <small
          >Mikäli toimeksiantosopimus ei ole päättynyt, pidä päättyen päivämäärä tyhjänä.</small
        >
        <v-row dense class="mb-2">
          <v-col cols="6" md="4" lg="3" xl="2">
            <v-menu
              ref="startMenu"
              v-model="startMenu"
              :close-on-content-click="false"
              min-width="290"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="formatDate(currentApartment.assignment.startDate)"
                  dense
                  outlined
                  v-bind="attrs"
                  :rules="validations.required"
                  v-on="on"
                  append-icon="mdi-calendar"
                  label="Alkaen"
                  v-prevent-manual-input
                  hide-details
                  clearable
                  @click:clear="currentApartment.assignment.startDate = null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="currentApartment.assignment.startDate"
                first-day-of-week="1"
                @input="startMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="6" md="4" lg="3" xl="2">
            <v-menu ref="endMenu" v-model="endMenu" :close-on-content-click="false" min-width="290">
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="formatDate(currentApartment.assignment.endDate)"
                  dense
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  append-icon="mdi-calendar"
                  label="Päättyen"
                  v-prevent-manual-input
                  hide-details
                  clearable
                  @click:clear="currentApartment.assignment.endDate = null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="currentApartment.assignment.endDate"
                first-day-of-week="1"
                @input="endMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <!-- Reward options -->
        <v-row dense>
          <v-col cols="6" md="4" lg="3" xl="2">
            <h4>Veloitustapa</h4>
            <v-radio-group
              v-model="currentApartment.assignment.rewardType"
              :mandatory="true"
              hide-details
              style="margin-top: 0px"
            >
              <v-radio label="Prosenttikerroin vuokralle" value="percent"></v-radio>
              <v-radio label="Kiinteä hinta" value="fixed"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row dense>
          <!-- Percent -->
          <v-col
            v-if="currentApartment.assignment.rewardType === 'percent'"
            cols="6"
            md="4"
            lg="3"
            xl="2"
          >
            <v-text-field
              v-model.number="currentApartment.assignment.percent"
              type="number"
              step="0.01"
              max="100"
              label="Kerroin prosentteina"
              outlined
              :rules="
                validations.required
                  .concat(validations.positiveNumber)
                  .concat(validations.maxNumber(100))
              "
              v-prevent-paste
              dense
              suffix="%"
            ></v-text-field>
          </v-col>

          <!-- Fixed -->
          <v-col v-else cols="6" md="4" lg="3" xl="2">
            <v-text-field
              v-model.number="currentApartment.assignment.fixedAmountPerMonth"
              type="number"
              step="0.01"
              label="Hinta"
              outlined
              v-prevent-paste
              :rules="validations.required.concat(validations.positiveNumber)"
              dense
              suffix="€/kk"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="6" md="4" lg="3">
            <h4>Kommentit</h4>
            <small>Kommentit näkyvät myös excel-raportissa.</small>
            <v-textarea v-model="currentApartment.assignment.comments" outlined dense></v-textarea>
          </v-col>
        </v-row>

        <h4>Välittäjien palkkiot</h4>

        <div v-for="(realtor, index) in currentApartment.assignment.realtors" :key="index + 'c'">
          <v-row dense>
            <v-col cols="6" md="4" lg="3" xl="2">
              <v-autocomplete
                :value="realtor.realtorId"
                item-text="name"
                item-value="_id"
                outlined
                small-chips
                :items="activeRealtors"
                return-object
                label="Välittäjä"
                dense
                hide-details
                @input.native="getRealtors"
                @change="setRealtor($event, realtor)"
              ></v-autocomplete>
            </v-col>

            <v-col ref="ownerField" cols="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="realtor.name"
                outlined
                dense
                :rules="validations.required"
                label="Nimi"
                disabled
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="4" lg="3" xl="2">
              <div class="details-container">
                <v-text-field
                  v-model.number="realtor.rewardPercent"
                  outlined
                  dense
                  type="number"
                  label="Palkkio"
                  hide-details
                  :rules="
                    validations.required
                      .concat(validations.positiveNumber)
                      .concat(validations.maxNumber(100))
                  "
                  suffix="%"
                ></v-text-field>

                <v-icon
                  style="margin-bottom: 5px"
                  class="ml-2"
                  color="error"
                  small
                  @click="removeRealtor(index)"
                >
                  mdi-delete
                </v-icon>
              </div>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" lg="9" xl="6">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </div>

        <v-row dense :class="currentApartment.assignment.realtors.length > 0 ? 'mt-2' : ''">
          <v-col cols="12" md="4">
            <v-btn
              :disabled="isDisabled('realtor', 'owners')"
              small
              color="primary"
              @click="addAssignmentRealtor"
              >Lisää välittäjä</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { debounce } from "../../utils/helpers";
import mixins from "../../mixins/mixins";
import validations from "@/validations";

export default {
  mixins: [mixins],

  data() {
    return {
      startMenu: false,
      endMenu: false,
      realtorInput: "",
      validations,
    };
  },

  computed: {
    ...mapState("apartment", ["currentApartment"]),
    ...mapState("realtor", ["activeRealtors"]),
  },

  watch: {
    realtorInput: debounce(function (newVal) {
      this.searchRealtor(`/api/v1/realtor/search-realtor?search=${newVal}`);
    }, 1000),
  },

  methods: {
    ...mapActions("realtor", ["searchRealtor"]),
    ...mapMutations("apartment", ["addAssignmentRealtor", "deleteAssignmentRealtor"]),

    getRealtors(event) {
      this.realtorInput = event.target.value;
    },

    setRealtor(event, realtor) {
      if (event) {
        realtor.name = event.name;
        realtor.realtorId = event._id;
      }
    },

    removeRealtor(index) {
      if (confirm("Oletko varma, että haluat poistaa välittäjän?")) {
        this.deleteAssignmentRealtor(index);
      }
    },
  },
};
</script>

<style scoped>
.details-container {
  display: flex;
}
</style>
